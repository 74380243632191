import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import isClient from 'utils/isClient'
import { isMobile } from 'utils/getDevice'
import { sliderImages } from './constants'
import { navigate } from '@reach/router'

import enhancer from './hooks'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, A11y } from 'swiper'
import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Pagination, A11y])

const ManagementHero = ({ onClickSidebar }) => {

  return (
    <section className="management-hero --manager">
      <div className="container">
        <div className="management-hero__content">
          <div className="management-hero__body">
            <Typography as="h4" variant="caption" className="management-hero__body--caption" >
              Previdência reinventada com excelência
            </Typography>
            <Typography as="h2" variant="headline5" className="management-hero__body--title" >
              A Onze Gestora traz para você o investimento que encaixa no seu perfil
            </Typography>
            <Typography as="p" variant="body1" className="management-hero__body--text" >
              Ao contratar a solução de previdência da Onze você pode incluir os fundos da nossa própria gestora com a excelência Onze que você conhece, 
              além de ter a seu dispor todas as gestoras do Brasil no portfólio da Icatu
            </Typography>

            <Button
              onClick={() => isClient() && window.open(
                'https://materiais.onze.com.br/onze-gestora-fundos'
              )}
              label="Conheça a Onze Gestora"
              iconId="right-arrow"
              iconPosition="right"
              buttonStyle="outline"
              customClass="management-hero__body--button"
            />
          </div>

          <div className="management-hero__image">
            <img
              src="/images/img-onze-gestora-fundos03.png"
              alt="Imagem com os logos do portifólio de fundos de investimentos."
            />
          </div>
        </div>

      </div>
    </section>
  )
}

export default enhancer(ManagementHero)
